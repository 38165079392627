<template>
  <div class="offline-record-review">
    <table-list
      title="线下报名记录列表"
      :loading="loading"
      :data="dataList"
      :tabs-list="tabsList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :options="{ selection: true }"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
    <el-dialog title="审核确认" :visible.sync="dialogVisible" width="500px">
      <el-form ref="reviewForm" :model="reviewForm" label-width="80px">
        <el-form-item label="审核:">
          <el-select v-model="reviewForm.examineStatus" placeholder="请选择">
            <el-option :value="1" label="通过"></el-option>
            <el-option :value="2" label="驳回"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="reviewForm.examineStatus == 2" label="驳回说明:">
          <el-input
            v-model="reviewForm.remarks"
            type="textarea"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleReview()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//表格tabs
const tabsList = [
  {
    prop: 'examineStatus',
    activeValue: '',
    children: [
      { value: 0, label: '待审核' },
      { value: 1, label: '已通过' },
      { value: 2, label: '未通过' },
    ],
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '证书名称',
    prop: 'productName',
  },
  {
    label: '购买人',
    prop: 'userName',
  },
  {
    label: '手机号',
    prop: 'phone',
  },
  {
    label: '代理商',
    type: 'select',
    prop: 'agentUserId',
    children: _this.agentUserList,
  },
  {
    label: '提交时间',
    type: 'picker',
    prop: 'commitTime',
    pickerDate: _this.pickerDate,
  },
  {
    label: '审核时间',
    type: 'picker',
    prop: 'examineTime',
    pickerDate: _this.pickerDate,
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '批量审核',
    method: () => {
      _this.handleReviewOpen()
    },
  },
  {
    label: '导出',
    method: _this.handleexportData,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'productName',
    label: '证书名称',
    minWidth: 160,
    twoLines: true,
  },
  {
    prop: 'skuName',
    label: '服务名称',
    minWidth: 110,
  },
  {
    prop: 'payType',
    label: '支付渠道',
    minWidth: 76,
  },
  {
    prop: 'payAmount',
    label: '实付金额',
    minWidth: 100,
    align: 'right',
  },
  {
    prop: 'userName',
    label: '购买人姓名',
    minWidth: 90,
  },
  {
    prop: 'phone',
    label: '手机号',
    minWidth: 113,
  },
  {
    prop: 'agentUserName',
    label: '代理商',
    minWidth: 90,
  },
  {
    prop: 'commitTime',
    label: '提交时间',
    minWidth: 150,
  },
  {
    prop: 'examineStatus',
    label: '审核状态',
    minWidth: 80,
    formatter: row => {
      return ['待审核', '已通过', '未通过'][row.examineStatus]
    },
  },
  {
    prop: 'examineTime',
    label: '审核时间',
    minWidth: 150,
    formatter: row => {
      return row.examineTime || '—'
    },
  },
  {
    prop: 'examineUserName',
    label: '审核人',
    minWidth: 62,
    formatter: row => {
      return row.examineUserName || '—'
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '审核',
    method: row => {
      _this.handleReviewOpen(row.id)
    },
    isShow: item => item.examineStatus == 0,
  },
]
import TableList from '@/components/TableList'
import {
  offlineSalesRecordReg,
  distributorList,
  offlineSalesRecordRegExport,
  offlineSalesRecordReview,
} from '@/api/center'
import to from 'await-to'
export default {
  name: 'OfflineRecordReview',
  components: {
    TableList,
  },
  data() {
    return {
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
      loading: false,
      tabsList,
      searchForm,
      btns,
      columns,
      operates,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      selectList: [],
      dataList: [],
      agentUserList: [],
      dialogVisible: false,
      reviewForm: {
        examineStatus: 1,
      },
      reviewIds: [],
    }
  },
  mounted() {
    this.getList()
    this.getAgentUserList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        offlineSalesRecordReg({
          current,
          size,
          ...this.queryParams,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.dataList = res.data.list
    },
    async getAgentUserList() {
      const [res, err] = await to(distributorList({}))
      if (err) return this.$message.warning(err.msg)
      this.agentUserList = res.data.map(item => ({
        label: item.distributorName,
        value: item.userId,
      }))
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      if (queryParams.commitTime && queryParams.commitTime !== 0) {
        this.queryParams.commitBeginTime = queryParams.commitTime[0]
        this.queryParams.commitEndTime = queryParams.commitTime[1]
      } else {
        this.queryParams.commitBeginTime = ''
        this.queryParams.commitEndTime = ''
      }
      if (queryParams.examineTime && queryParams.examineTime !== 0) {
        this.queryParams.examineBeginTime = queryParams.examineTime[0]
        this.queryParams.examineEndTime = queryParams.examineTime[1]
      } else {
        this.queryParams.examineBeginTime = ''
        this.queryParams.examineEndTime = ''
      }
      this.getList()
    },
    handleAdd() {
      this.$router.push('/scenter/mySalesRecord/add')
    },
    //导出
    async handleexportData() {
      let ids = []
      this.selectList.map(v => ids.push(v.id))
      const { current, size } = this.pager
      const [res, err] = await to(
        offlineSalesRecordRegExport({ current, size, ...this.queryParams, ids }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '线下报名记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleReviewOpen(id) {
      this.reviewForm = {
        examineStatus: 1,
      }
      this.reviewIds = []
      if (id) {
        this.reviewIds = [id]
        this.dialogVisible = true
      } else if (this.selectList.length) {
        for (let i = 0; i < this.selectList.length; i++) {
          const element = this.selectList[i]
          if (element.examineStatus != 0) {
            return this.$message.warning('只能选择未审核的记录')
          }
        }
        this.selectList.map(v => this.reviewIds.push(v.id))
        this.dialogVisible = true
      } else {
        return this.$message.warning('请先选择报名记录')
      }
    },
    async handleReview() {
      const [, err] = await to(
        offlineSalesRecordReview({ ...this.reviewForm, ids: this.reviewIds }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$message.success('审核成功')
      this.dialogVisible = false
      this.getList()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>
.offline-record-review {
  ::v-deep.table-search-bar .el-form-item .el-input,
  ::v-deep.table-search-bar .el-form-item .el-select {
    width: 188px !important;
  }
  .subTitle {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-left: 20px;
    border-left: 1px solid #e2e2e2;
    padding-left: 20px;
    line-height: 20px;
    .textDes {
      color: #909399;
    }
    .textMon {
      color: #ff7b33;
    }
  }
  ::v-deep .table-list {
    .top-bar {
      padding: 14px 0;
    }
  }
}
</style>
